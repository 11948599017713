html {
  position: relative;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  background-color: white;
  min-height: 100vh;
}

/* HL's & Text */
@font-face {
  font-family: "montserrat light";
  src: url("fonts/Montserrat/Montserrat-Light.ttf")
}

@font-face {
  font-family: "montserrat regular";
  src: url("fonts/Montserrat/Montserrat-Regular.ttf")
}

@font-face {
  font-family: "montserrat bold";
  src: url("fonts/Montserrat/Montserrat-Bold.ttf")
}

@font-face {
  font-family: "montserrat black";
  src: url("fonts/Montserrat/Montserrat-Black.ttf")
}

p {
  font-family: 'montserrat light';
  font-size: 18px;
  color: black;
  margin: 0;
}

.lead {
  font-size: 32px;
}

a {
  font-family: 'montserrat bold';
  font-size: 18px;
  color: black;
  margin: 0;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

h1 {
  font-family: 'Montserrat black';
  font-size: 40px;
  color: black;
  margin: 0;
}

h2 {
  font-family: 'Montserrat black';
  font-size: 32px;
  color: black;
  margin: 0;
  margin-bottom: 10px;
}

h3 {
  font-family: 'Montserrat light';
  font-size: 22px;
  color: black;
  margin: 0;
}

h4 {
  font-family: 'Montserrat bold';
  font-size: 14px;
  color: black;
  margin: 0;
}

h5 {
  font-family: 'Montserrat bold';
  font-size: 24px;
  color: black;
  margin: 0;
}

.alert {
  margin-top: 100px;
  width: 100vw;
  opacity: 0.98
}

/* Allgeimeines */
.white {
  color: white;
}

.bold {
  font-weight: bold;
}

.border-thick {
  border-width: 3px;
  border-color: black;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-bottom-big {
  margin-bottom: 70px;
}

.margin-bottom-huge {
  margin-bottom: 150px;
}

.margin-bottom-gigantic {
  margin-bottom: 80px;
}

.margin-right {
  margin-right: 15px;
}

.margin-right-small {
  margin-right: 8px;
}

.margin-left {
  margin-left: 15px;
}

.rounded-corners {
  border-radius: 10px;
}

.scalabl-headers {
  font-size: 18px;
}

.no-decoration {
  text-decoration: none !important;
}

.btn-hover-white-inverse:hover {
  background-color: white;
  color: black;
}

.btn-hover-white:hover {
  color: white;
  text-decoration: none;
}

.btn-hover-grey:hover {
  color: grey;
}

.carousel-indicators li {
  background-color: #2d373c;
  position: relative;
  top: 60px;
  width: 20px;
  height: 20px;
  margin: 0 10px;
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  height: 29px;
  box-shadow: none;
  position: absolute;
  opacity: 1;
  top: 102%;
}

.carousel-control-prev {
  background: url(./bg-images/carousel-next.png) no-repeat !important;
  z-index: 10;
  transform: rotate(180deg);
}

.carousel-control-next {
  background: url(./bg-images/carousel-next.png) no-repeat !important;
  z-index: 10;
}


/* Header */
.navbar {
  position: fixed;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  padding: 7.5px 0px 7.5px 0px;
 }

 .navbar-light{
  border-color: none !important;
  color: black !important;
 }

 .navbar-nav {
  display: flex;
  justify-content: flex-end;
 }

 @media (max-width: 992px){
  .navbar {
  justify-content: space-between;
  }
 }

 .nav-item {
  display: flex;
  align-items: center;
  margin-left: 60px;
 }

 @media (max-width: 992px){
  .nav-item {
  margin-left: 0px;
  justify-content: center;
  }
  .special-item{
    margin-top: 18px;
  }
  .special-item2{
    margin-top: 5px;
    margin-bottom: -5px;
  }
 }

 .item-hover{
  transition: 0.6s;
 }

 .item-hover:hover{
  color: #87BD25;
  transition: 0.3s;
 }

 .item-chosen{
  color: #87BD25;
  margin: 0;
 }

 .text-dec{
  text-decoration: none !important;
 }

 .icon {
  height: 55px;
 }

 .avoidNav {
  height: 85px;
 }

 .header-button{

  background-color: #87BD25;
  border-radius: 50px;
  height: 46px;
 }
.header-button:hover {
  color: white;
}
 .header-button-new {
   background-color: #87BD25;
    border-radius: 50px;
    padding: 5px 20px;

 }

 @media (max-width: 992px){
   .header-button-new {
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
   }

 }


 li.d-lg-none.center::marker{
   display: none;
   opacity: 0;
 }
 .result-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #87BD25;
  border-radius: 50px;
  padding: 5px 20px 5px 20px;
 }
 .result-button:hover {
   color: white;
 }
 /* .result-button:hover{
  background-color: #87BD25;
  border-radius: 1px;
  transition: 0.4s;
  color: white;
 } */

 /* .header-button-text {
  font-family: 'Montserrat bold';
  color: white;
  margin: 0;
 } */


 @media (max-width: 992px){
  .button-hide {
  display: none !important;
  }
  .login-button {
    margin-bottom: 26px;
  }
 }

 .login-button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 80px;
  border: 2px solid black;
  border-radius: 50px;
  transition: 0.4s;
 }

 .login-button:hover{
   border-radius: 0px;
 }

 .item-line{
  background-color: #87BD25;
  height: 2px;
  width: 64px;
  border-radius: 10px;
  position: absolute;
  margin-top: 3px;
  margin-left: 1px;
  animation: fadeIn 0.5s;
 }

 @media (max-width: 992px){
  .wrapper-line {
  display: flex;
  justify-content: center;
  }
  .item-line {
    margin-top: 20px;
  }
 }

 @keyframes fadeIn {
  from{
  width: 0px;
  }

  to{
  width: 64px;
  }
 }

 @media (max-width: 992px){
  .hide-menu-placeholder {
  display: none;
  }
 }

.burger{
  width: 34px;
  height: 26px;
}

.fleischundsalat{
  width: 26px;
  height: 26px;
}

.responsive-margin-brand{
  margin-right: 0 !important;
}


 .responsive-margin-burger{
   border: 0px;
 }

 @media (max-width: 992px) and (min-width: 600px){
  .responsive-margin-brand {
  margin-left: 10%;
  }
  .responsive-margin-burger {
    margin-right: 10%;
    }
 }


 @media (max-width: 600px){
  .responsive-margin-brand {
  margin-left: 3%;
  }
  .responsive-margin-burger {
    margin-right: 3%;
    }
 }



/* Home  */
.home__teaser,
.home__buttons,
.home__audience,
.anleitung__margin {
  margin-bottom: 80px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.react-player {
  position: absolute;
  top:0;
  left:0;
}

.home-nav__button {
  width: 250px;
  height: 250px;
  padding: 30px 24px;
  margin-left: 50px;
  margin-bottom: 50px;
  background: none;
}

.home-nav__button__img {
  opacity: 0.3;
}

.home__buttons {
  position: relative;
  right: 20px;
}

.home__button {
  position: relative;
  bottom: 50px;
  padding: 0.5%;
  transition: 0.3s;

}
.home__button:hover {
  padding: 0;
  transition: 0.3s;
}

.home-nav__button img:hover {
  opacity: 1;
  cursor: pointer;
}

.home__button__text-1 {
  bottom: 100px;
}

.home__button__text-2 {
  bottom: 118px;
}

.home__button__text-3 {
  bottom: 131px;
}

.game__headline {
  font-size: 24px;
}

.quadrant-2 {
  transform: rotate(90deg);
}

.quadrant-3 {
  transform: rotate(180deg);
}

.quadrant-4 {
  transform: rotate(-90deg);
}

.greyColor {
  color: grey;
}

.game__go-down {
  width: 37px;
}

.game__go-down__button {
  position: relative;
  top: 10px;
  left: 11px;
}

.gameplay__link {
  border: 1px solid;
  border-color: white;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  top: 10px
}



.selected {
  background: none;
  border: none;
  opacity: 1;
}

.notSelected {
  background: none;
  border: none;
  padding: 5%;
  opacity: 0.85;
}

.notSelected:hover {
  opacity: 1;
}

.progress__bar {
  border-width: 2px;
  border-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: grey;
  width: 12px;
  height: 500px;
  transform: rotate(180deg);
  position: relative;
  top: 15px;
}

.progress__bar--progress {
  border-radius: 10px;
  background-color: #87be2d;

}

.progress-0 {
  height: 0;
}

.progress-1 {
  height: 6.25%
}

.progress-2 {
  height: 12.5%
}

.progress-3 {
  height: 18.75%
}

.progress-4 {
  height: 25%
}

.progress-5 {
  height: 31.25%
}

.progress-6 {
  height: 37.5%
}

.progress-7 {
  height: 43.75%
}

.progress-8 {
  height: 50%
}

.progress-9 {
  height: 56.25%
}

.progress-10 {
  height: 62.5%
}

.progress-11 {
  height: 68.75%
}

.progress-12 {
  height: 75%
}

.progress-13 {
  height: 81.25%
}

.progress-14 {
  height: 87.5%
}

.progress-15 {
  height: 93.75%
}

.progress-16 {
  height: 100%
}

.anleitung__link {
  border-radius: 50px;
  margin: 150px 0;
}

.anleitung {
  padding-top: 80px;
}

.next__button {
  border-radius: 20px;
  border-width: 2px;
  border-color: white;
}

.next__button--placeholder {
  border-width: 2px;
}

.stepCounter {
  color: white;
  font-size: 30px;
}

.werteBild {
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
}

.game {
  background-color: #2d373c;
  min-height: 100vh;
}

/* erfahre mehr */
.text-green {
  font-family: 'Montserrat black';
  font-size: 32px;
  color: #87BD25;
  margin: 0;
}

/*Result Image */


.polar-underlay {
  width: 111%;
  position: absolute;
  top: -8%;
  left: -5.5%;
  z-index: -1;
  opacity: 0.7;
}
.results__polar {
  transform: rotate(-90deg);
}

.resultBild {
  width: 100px;
  margin: 4px;
  filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.1));
  border-radius: 5px;
}


.result__go-down {
  margin: 50px 0;
}

.result__goo {
  height: 100vh;
  position: relative;
  bottom: 150px;
}
.shopPreview__img {
  max-width: 100%;
}
.shopPreview__button {
  background-color: #87BD25;
  border-radius: 50px;
  padding: 5px 20px;
  
}
.shopPreview__button:hover {
  text-decoration: none;
  color: white
}
/* Loading Screen */
.loading {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 153, 212, 0) calc(15% + 100px), rgba(0, 99, 138, 0) calc(85% + 100px), rgba(0, 0, 0, 0.15) 100%);
}

div.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.loading>div {
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin: 2vw;
  background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.haltung1 {
  background-color: #e65014;
  animation: bounce 1.5s 0.5s linear infinite;
}

.haltung2 {
  background-color: #b99655;
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: 0.1s;
}

.haltung3 {
  background-color: #5087c3;
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: 0.2s;
}

.haltung4 {
  background-color: #f5a500;
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: 0.3s;
}

.haltung5 {
  background-color: #87be2d;
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: 0.4s;
}

.haltung6 {
  background-color: #2db4aa;
  animation: bounce 1.5s 0.5s linear infinite;
  animation-delay: 0.6s;
}
.haltungs-slider__icon {
  margin: 20px 0;
}

.selbstorientiert::before {
  content: '';
  background: #e65014;
  width: 5000px;
  height: 890px;
  position: absolute;
  z-index: -10;

}
.gemeinschaftsbestimmt::before {
  content: '';
  background: #b99655;
  width: 5000px;
  height: 890px;
  position: absolute;
  z-index: -10;

}
.rationalistisch::before {
  background: #5087c3;
}
.eigenbestimmt::before {
  background: #f5a500;
}
.relativistisch::before {
  background: #87be2d;
}
.systemisch::before {
  background: #2db4aa;
}
@keyframes bounce {
  0%, 50%, 100% {
    transform: scale(1);
    filter: blur(0px);
  }

  25% {
    transform: scale(0.6);
    filter: blur(3px);
  }

  75% {
    filter: blur(3px);
    transform: scale(1.4);
  }
}

.cardstack {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  user-select: none;
  position: fixed;
  overflow: hidden;
}


.footer__text {
  font-size: 12px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .home__button {
    position: relative;
    bottom: 50px;
  }

  .resultBild {
    width: 120px;
  }
  .progress__bar {
    height: 600px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .home__button {
    position: relative;
    bottom: 70px;
  }

  .resultBild {
    width: 150px;
  }
  .progress__bar {
    height: 1100px;
  }

  .scalabl-headers {
    font-size: 24px;
  }
  .selbstorientiert::before {
    height: 700px;
  }
  .gemeinschaftsbestimmt::before {
    height: 700px;
  }
  .rationalistisch::before {
    height: 710px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .home__button {
    position: relative;
    bottom: 100px;
  }
  .margin-bottom-gigantic {
    margin-bottom: 200px;
  }
  .progress__bar {
    height: 420px;
  }

  .scalabl-headers {
    font-size: 32px;
  }
  .werteBild {
    width: 110%;

  }


  .game__headline {
    font-size: 30px;
  }
  .selbstorientiert::before {
    right: calc(15%);
    height: 540px;
  }
  .gemeinschaftsbestimmt::before {
    left: calc(15%);
    height: 540px;
  }
  .rationalistisch::before {
    height: 560px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .margin-bottom-gigantic {
    margin-bottom: 220px;
  }
  .home__button {
    position: relative;
    bottom: 120px;
  }
  .progress__bar {
    height: 570px;
  }
}

/* Huge shit  */
@media (min-width: 1500px) {
  .home__button {
    position: relative;
    bottom: 200px;
  }

  .game__headline {
    font-size: 34px;
  }
}
.pdf__download {
  border: none;
  font-family: "montserrat bold";
}
/* .img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  
  width: 100px;
  height: 100px;
} */