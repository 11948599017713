.profile__team {
    height: 250px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #87BD25;
    
}
.profile__team__link {
    width: 90%
}
.profile__team__link:hover {
    text-decoration: none;
}
.profile__team__name {
    color:white;

}