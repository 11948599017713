.gameSelect__button {
    background-color: #87BD25;
    color: white;
    margin: 0 10px;
    padding: 10px 20px;
    border-radius: 50px;
    text-transform: uppercase;
    outline: none;
    border: none;
    font-family: 'montserrat bold';
}
.gameSelect__button:hover {
position: relative;
bottom: 2px;
box-shadow: 0 0 2px rgba(33,33,33,.8); 
}